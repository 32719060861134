<template>
  <div class="popup_wrap" style="width:1000px;">
    <button class="layer_close" @click.prevent="$emit('close')">close</button>
    <div class="popup_cont">
      <h1 class="page_title">{{ $t('cp.CTRL010P020.001') }}</h1>
      <div class="content_box">
        <div id="realgrid" style="width: 100%; height: 600px" />
      </div>
    </div>
  </div>
</template>

<script>
import { GridView, LocalDataProvider } from 'realgrid'
import actionListMng from '@/api/rest/cp/actionListMng'
let gridView = GridView
let provider = LocalDataProvider

const fields = [
  { fieldName: 'apiCd', dataType: 'text' },
  { fieldName: 'apiFunction', dataType: 'text' },
  { fieldName: 'apiCategory', dataType: 'text' },
  { fieldName: 'apiCategoryNm', dataType: 'text' },
  { fieldName: 'apiDesc', dataType: 'text' }
]

const columns = [
  { name: 'apiCd', fieldName: 'apiCd', header: { text: app.$t('cp.CTRL010P020.002') }, editable: false, width: 25 },
  { name: 'apiFunction', fieldName: 'apiFunction', header: { text: app.$t('cp.CTRL010P020.003') }, editable: false, width: 60 },
  { name: 'apiCategory', fieldName: 'apiCategory', header: { text: app.$t('cp.CTRL010P020.004') }, editable: false, width: 45 },
  { name: 'apiCategoryNm', fieldName: 'apiCategoryNm', header: { text: app.$t('cp.CTRL010P020.005') }, editable: false, width: 45, renderer: { showTooltip: true } },
  { name: 'apiDesc', fieldName: 'apiDesc', header: { text: app.$t('cp.CTRL010P020.006') }, editable: false, styleName: 'text_left', renderer: { showTooltip: true } }
]

export default {
  name: 'ApiListPopup',
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          apiCd: ''
        }
      }
    }
  },
  data: function () {
    return {
      list: []
    }
  },
  mounted () {
    const $vm = this
    provider = new LocalDataProvider(true)
    gridView = new GridView('realgrid')
    gridView.setDataSource(provider)
    // 그리고 헤더 높이 조정
    gridView.header.heights = [30]
    gridView.displayOptions.rowHeight = 34
    gridView.displayOptions.fitStyle = 'even'
    gridView.footers.visible = false
    gridView.setStateBar({
      visible: false
    })
    gridView.setCheckBar({
      visible: false
    })
    gridView.footers.visible = false
    // 그리드 필드 생성
    provider.setFields(fields)
    gridView.setColumns(columns)
    gridView.displayOptions.selectionStyle = 'rows'

    gridView.setSortingOptions({
      keepFocusedRow: true,
      style: 'inclusive',
      showSortOrder: true
    })

    gridView.onCellDblClicked = function (grid, clickData) {
      if (clickData.cellType === 'data') {
        $vm.$emit('close', $vm.list[clickData.dataRow].apiCd)
        $vm.layerClose()
      }
    }

    $vm.searchData()
  },
  methods: {
    searchData () {
      actionListMng.apiList().then(response => {
        this.list = response.data
        provider.setRows(this.list)
        if (this.parentInfo.actionCd !== '') {
          this.list.forEach((item, index) => {
            if (this.parentInfo.apiCd === item.apiCd) {
              gridView.setCurrent({ itemIndex: index }, true)
            }
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    layerClose: function () {
      $('.popup_dim').fadeOut()
      $('body,html').removeAttr('style')
      return false
    }
  }
}
</script>
